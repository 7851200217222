import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import * as SearchMasjidsActions from '../../store/actions/search-masjids.actions';
import { SearchFilters } from '../../interfaces/search-masjids.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { AdvancedSearchComponent } from '../advanced-search/advanced-search.component';

@Component({
    selector: 'app-search-box',
    standalone: true,
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatChipsModule,
        TranslocoModule,
        MatDialogModule
    ],

})
export class SearchBoxComponent implements OnInit {
    @Output() filtersChanged = new EventEmitter<SearchFilters>();
    searchForm: FormGroup;
    currentFilters: SearchFilters | null = null;
    hasActiveFilters = false;
    filters: SearchFilters = {
        city: '',
        country: '',
        rating: 0,
        distance: 10,
        facilities: []
    };

    constructor(
        private fb: FormBuilder,
        private store: Store,
        private dialog: MatDialog,
        private router: Router
    ) {
        this.searchForm = this.fb.group({
            searchQuery: ['']
        });
    }

    ngOnInit() {
        // Only trigger search on user input with debounce
        this.searchForm.get('searchQuery')?.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                filter(query => query?.length > 2) // Only search if query is longer than 2 chars
            )
            .subscribe(query => {
                this.performSearch(query, false);
            });
    }

    onSearch() {
        const query = this.searchForm.get('searchQuery')?.value;
        if (query) {
            this.performSearch(query, true);
        }
    }

    clearSearch() {
        this.searchForm.patchValue({ searchQuery: '' });
        this.currentFilters = null;
        this.hasActiveFilters = false;
        this.store.dispatch(SearchMasjidsActions.clearSearch());
        // Navigate back if on results page
        if (this.router.url.includes('/results')) {
            this.router.navigate(['/']);
        }
    }

    openAdvancedSearch() {
        const dialogRef = this.dialog.open(AdvancedSearchComponent, {
            width: '600px',
            data: this.currentFilters
        });

        dialogRef.afterClosed().subscribe(filters => {
            if (filters) {
                this.currentFilters = filters;
                this.hasActiveFilters = this.hasAnyFilters(filters);
                const query = this.searchForm.get('searchQuery')?.value;
                this.performSearch(query, true);
            }
        });
    }

    removeFilter(key: keyof SearchFilters) {
        if (this.currentFilters) {
            const updatedFilters = { ...this.currentFilters };
            if (key === 'facilities') {
                delete updatedFilters.facilities;
            } else {
                delete updatedFilters[key];
            }
            this.currentFilters = Object.keys(updatedFilters).length > 0 ? updatedFilters : null;
            this.hasActiveFilters = this.hasAnyFilters(this.currentFilters);
            const query = this.searchForm.get('searchQuery')?.value;
            this.performSearch(query, true);
        }
    }

    private hasAnyFilters(filters: SearchFilters | null): boolean {
        if (!filters) return false;
        return Object.values(filters).some(value => {
            if (Array.isArray(value)) return value.length > 0;
            if (typeof value === 'number') return value > 0;
            return !!value;
        });
    }

    private performSearch(query: string, shouldNavigate: boolean = false) {
        if (!query?.trim()) return;

        // Dispatch search action
        this.store.dispatch(SearchMasjidsActions.searchMasjids({
            request: {
                query,
                filters: this.currentFilters || undefined,
                page: 1,
                limit: 10
            }
        }));

        // Navigate to results page with query params
        if (shouldNavigate) {
            const queryParams = {
                q: query,
                ...this.currentFilters
            };
            
            this.router.navigate(['/search/results'], {
                queryParams,
                queryParamsHandling: 'merge'
            });
        }
    }

    resetAllFilters(): void {
        this.filters = {
            city: '',
            country: '',
            rating: 0,
            distance: 10,
            facilities: []
        };
        this.currentFilters = null;
        this.filtersChanged.emit(this.filters);
    }
}
