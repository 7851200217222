<div class="search-container">
    <div class="search-row">
        <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
            <mat-form-field appearance="outline" class="search-field">
                <mat-label>{{ 'search.placeholder' | transloco }}</mat-label>
                <input matInput
                       formControlName="searchQuery"
                       [placeholder]="'search.inputPlaceholder' | transloco">
                <button mat-icon-button matSuffix
                        type="button"
                        (click)="clearSearch()"
                        *ngIf="searchForm.get('searchQuery')?.value">
                    <mat-icon>close</mat-icon>
                </button>
                <button mat-icon-button matSuffix type="submit" color="accent">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </form>
        <button mat-flat-button
                color="accent"
                class="advanced-search-btn"
                (click)="openAdvancedSearch()">
            <mat-icon>tune</mat-icon>
            {{ 'search.advancedSearch' | transloco }}
        </button>
    </div>

    <!-- Active Filters Section -->
    <div class="active-filters" *ngIf="hasActiveFilters && currentFilters">
        <div class="filters-header">
            <span class="filters-title">{{ 'search.filters.title' | transloco }}</span>
            <button mat-button
                    color="warn"
                    class="reset-filters-btn"
                    type="button"
                    (click)="resetAllFilters()">
                <mat-icon>refresh</mat-icon>
                {{ 'search.filters.reset' | transloco }}
            </button>
        </div>
        <mat-chip-set aria-label="Active filters">
            <mat-chip *ngIf="currentFilters.city" (removed)="removeFilter('city')" [removable]="true">
                {{ 'search.filters.city' | transloco }}: {{ currentFilters.city }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip *ngIf="currentFilters.country" (removed)="removeFilter('country')" [removable]="true">
                {{ 'search.filters.country' | transloco }}: {{ currentFilters.country }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip *ngIf="currentFilters.rating" (removed)="removeFilter('rating')" [removable]="true">
                {{ 'search.filters.rating' | transloco }}: {{ currentFilters.rating }}★
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip *ngIf="currentFilters.distance" (removed)="removeFilter('distance')" [removable]="true">
                {{ 'search.filters.distance' | transloco }}: {{ currentFilters.distance }}km
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip *ngFor="let facility of currentFilters.facilities" (removed)="removeFilter('facilities')" [removable]="true">
                {{ 'search.facilities.' + facility | transloco }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-set>
    </div>
</div>
