import { createAction, props } from '@ngrx/store';
import { SearchRequest, SearchResponse, SearchFilters } from '../../interfaces/search-masjids.interface';

export const searchMasjids = createAction(
    '[Search Masjids] Search Masjids',
    props<{ request: SearchRequest }>()
);

export const searchMasjidsSuccess = createAction(
    '[Search Masjids] Search Masjids Success',
    props<{ response: SearchResponse }>()
);

export const searchMasjidsFailure = createAction(
    '[Search Masjids] Search Masjids Failure',
    props<{ error: string }>()
);

export const updateFilters = createAction(
    '[Search Masjids] Update Filters',
    props<{ filters: SearchFilters }>()
);

export const clearSearch = createAction(
    '[Search Masjids] Clear Search'
); 